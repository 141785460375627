.loadingWrapper {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 10000;
    display: block !important;  
}
.loadingWrapper .loadingBg {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    background-color: #000;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    opacity: 0.6;
}
.loaderSpin {
    width: 64px;
    position: absolute;
    z-index: 10000;
    left: 50%;
    top: 50%;
    margin-top: -32px;
    margin-left: -32px;
    border-radius: 8px;
}