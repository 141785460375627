.color-primary-title {
    color: #FFF;
}
/* .bg-gradient-main {
    background: linear-gradient(180deg, rgba(0,74,61,1) 0%, rgba(61,110,61,1) 100%);
}
.color-primary {
    color: #2F653D;
}
.text-gray {
    color: #757575;
}
.text-gray-2 {
    color: #B5B5B5;
} */


