/* Begin : ant-btn-primary  */
.ant-btn {
  padding: 4px 30px;
  height: 35px;
  border-radius: 5px;
}

.ant-btn span:nth-child(2) {
  margin-top: 1px;
}

.ant-btn > .anticon > svg {
  margin-top: 2px;
}

.btn-line {
  color: #004a3d;
  border: 1px solid #004a3d;
}
.btn-line:hover,
.btn-line:focus {
  color: #00866e;
  border: 1px solid #00866e;
}

.ant-btn-primary {
  background-color: #005bc2;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #1976d2;
  background-color: #fff !important;
  border-color: #1976d2;
}
/* End : ant-btn-primary  */

/* Begin : btn-gray  */
.btn-gray {
  background: #e5e5e5;
  color: #757575;
}
.btn-gray:hover,
.btn-gray:focus {
  background: #cfcfcf;
  color: #757575;
  border-color: #cfcfcf;
}
/* End : btn-gray  */

.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}

.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}
.ant-input-suffix {
  transform: translateY(4px);
}
.ant-input-password .ant-input {
  height: auto;
}
.ant-input {
  height: 35px;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 35px;
  border-radius: 6px;
  /* margin-top: -5px; */
}
.ant-input,
.ant-input-affix-wrapper {
  border-radius: 6px;
}
.ant-form-item .ant-form-item-label {
  padding-top: 2px;
}

/* Begin :: Menu-sider */
.ant-layout-sider {
  /* background: rgba(16,26,76,255); #003366 */
  background: linear-gradient(
    180deg,
    rgba(2, 28, 79, 255) 0%,
    rgba(42, 78, 139, 255) 100%
  );
}
.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark,
.ant-menu.ant-menu-sub.ant-menu-inline {
  /* background: rgba(54,82,104,255); */
  background: transparent;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: #2b3e4e;
  color: #fff;
}
.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 35px;
}

.ant-menu-dark .ant-menu-item a {
  text-decoration: none !important;
}

.ant-menu-dark .ant-menu-item-selected > a,
.ant-menu-dark .ant-menu-item-selected > span > a,
.ant-menu-dark .ant-menu-item-selected > a:hover,
.ant-menu-dark .ant-menu-item-selected > span > a:hover {
  color: #ffff;
  text-decoration: none;
}

/* End :: Menu-sider */

/* Begin :: hearder */
.ant-layout-header {
  display: flex;
  align-items: center;
  background: rgba(2, 28, 79, 255);
  -webkit-box-shadow: 0px 5px 19px -11px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 5px 19px -11px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 5px 19px -11px rgba(0, 0, 0, 0.3);
  padding: 0px 20px 0px 20px;
}

.ant-layout-header > span > svg {
  width: 1.5em;
  height: 1.5em;
  margin-top: 8px;
}

.ant-layout-header .username {
  padding: 0 5px;
}

.ant-dropdown-link {
  cursor: pointer;
}
/* End :: hearder */

/* Begin :: card */
.ant-card {
  border-radius: 6px;
}
/* End :: card */

/* Begin :: collapse */
.ant-collapse-header {
  background-color: #021c4f !important;
  color: white !important;
  border-radius: 6px !important;
}
.ant-collapse-header > svg {
  width: 1.5em;
  height: 1.5em;
  margin-top: -2.5px;
}

.ant-collapse {
  border-radius: 6px !important;
}

.ant-collapse > .ant-collapse-item {
  border-radius: 6px !important;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  transform: translateY(-2px);
  border-radius: 4px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-weight: 600;
  /* color: #FFFF; */
  background: #f2f2f2;
}
/* End :: collapse */

/* Begin :: table */
.ant-table-thead > tr > th {
  border-bottom: 3px solid #365268;
  background-color: #fff;
  color: #365268;
}
/* End :: table */

/* Begin :: pagination */
.anticon {
  transform: translateY(-4px);
}
.ant-pagination-item-active {
  border: #3394de;
  border-radius: 3px;
  background: #005bc2;
  color: white;
}
/* End :: pagination */

/* Begin :: switch */
.ant-switch {
  height: 30px;
  min-width: 60px;
}
.ant-switch-handle {
  width: 24px;
  height: 24px;
  top: 3px;
}
.ant-switch-handle::before {
  border-radius: 30rem;
}
.ant-switch-checked {
  background-color: #00996d;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 25px - 2px);
}
/* End :: switch */

/* Begin :: select */
.ant-select-arrow {
  transform: translateY(3px);
}
/* End :: select */

/* Begin :: modal */

.ant-modal-header {
  background: #f8f8f8;
}
.ant-modal-title {
  color: #3394de;
  font-size: 20px;
  font-weight: 600;
}
.ant-modal-body {
  /* padding: 24px 0px; */
}
.ant-modal-footer {
  text-align: left;
  padding: 20px 25px;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #e6fff0;
}

/* End :: modal */

.ant-upload-picture-card-wrapper.view-upload
  > .ant-upload.ant-upload-select.ant-upload-select-picture-card {
  width: 100%;
  height: 100%;
}

/* Begin :: Tabs */
.ant-tabs {
  color: #bec9d3;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff;
}
.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  border-bottom: 1px solid #e4e7ea;
}
.ant-tabs-tab {
  padding: 12px 20px;
}
.ant-tabs-tab:hover {
  color: #fff;
}
.ant-tabs-ink-bar {
  background: #fff;
}
.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active {
  color: #fff;
}
/* End :: Tabs */

.ant-layout {
  /* height: 100%; */
  min-height: 100%;
  background: #314554;
}

/* Start :: Radio-button */
.ant-radio-button-wrapper:first-child {
  border-radius: 5px 0 0 5px;
}
.ant-radio-button-wrapper:last-child {
  border-radius: 0px 5px 5px 0px;
}
.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: #2f653d;
  border-color: #2f653d;
}
.ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:first-child {
  border-right-color: #2f653d;
}
.ant-radio-button-wrapper:hover {
  color: #2f653d;
}
.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  background: #2f653d;
  border-color: #2f653d;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background: #2f653d;
}
.ant-picker-suffix {
  margin-top: 5px;
}
/* End :: Radio-button */

.site-layout {
  /* background-color: #021c4f !important; */
  background: linear-gradient(
    180deg,
    rgba(2, 28, 79, 255) 0%,
    rgba(102, 128, 179, 255) 100%
  );
}

/* .site-layout-background {
    background: transparent;
  } */

.login-root {
  background: linear-gradient(
    180deg,
    rgba(54, 82, 104, 255) 0%,
    rgba(94, 138, 169, 255) 100%
  );
  height: 100%;
}

.dashboard-bg-logo {
  text-align: center;
  position: sticky;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
}

.dashboard-bg-logo > img {
  width: 30%;
  max-width: 250px;
}

.ant-input-number.ant-input-number-disabled,
.ant-picker.ant-picker-disabled,
.ant-input.ant-input-disabled,
.ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  color: black !important;
  background-color: white !important;
}

/* .ant-form-item-label > label {
  height: 28px;
} */

/* .ant-form-item-control-input-content {
  margin: 7px auto auto auto;
} */

.ant-layout-footer {
  padding: 24px 40px !important;
}

.copyright {
  background-color: #021c4f;
  color: white;
  text-align: center;
}

.contact {
  margin-left: 6%;
}

.rowContact {
  margin-bottom: 2%;
}

.linkContact {
  text-decoration: none;
}

.linkHome {
  color: white;
  font-family: cursive;
  cursor: pointer;
}