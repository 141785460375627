@font-face {
  font-family: "Roboto";
  src: url(./fonts/Roboto/Roboto-Bold.ttf) format("truetype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: "NunitoSans";
  src: url(./fonts/Nunito_Sans/NunitoSans-Bold.ttf) format("truetype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

html,
body {
  font-family: NunitoSans, sans-serif, Roboto;
  height: 100%;
  background-color: #f6f7fa;
  font-size: 14px;
}
#root {
  height: 100%;
}
a {
  /* color: #00866e; */
  color: #005bc2;
}
a:hover,
a:focus {
  color: #004a3d;
}
.bg-gray {
  height: 100%;
}
.transform-nmu {
  transform: translateY(-2px) !important;
}
.text-link {
  text-decoration: underline;
  color: #757575;
  cursor: pointer;
}
.text-link {
  text-decoration: underline;
  color: #474747;
}
.center-screen {
  display: grid;
  place-items: center;
  height: 100%;
}

.circle-icon {
  background: #004a3d;
  border-radius: 30rem;
  width: 70px;
  height: 70px;
  margin: 0 auto;
  padding: 20px 0px;
}

.circle-icon img {
  width: 40px;
}

.card-highlight {
  -webkit-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.16);
}

.boder-main {
  background-color: #f6f7fa;
  border: 1px solid #ebebeb;
  padding: 30px;
}
.testobj {
  border: 1px solid red;
}
.logo-nmn-horizontal {
  padding: 9px 12px;
}
.logo-nmn-horizontal img {
  width: 60%;
}

.img-list-table img {
  width: 300px;
}

.loader_apploader__1KHL5 {
  position: fixed !important;
}

/* btn-google */
.button-google {
  border: #d8d8d8 1px solid;
  border-radius: 80%;
  width: 30px;
  cursor: pointer;
}
.button-google img {
  border-radius: 80%;
}
/* btn-google */

/* Chat */
.text-time {
  font-size: 10px;
  color: #b5b5b5;
}
.border-chat-group {
  background: #f6f6f6;
  padding: 15px;
}
.chat-group {
  height: 80vh;
  overflow-y: scroll;
}
.chat-boxmain {
  height: 70vh;
  overflow-y: scroll;
}
.chat-list {
  border: 1px solid #e3e3e3;
  background: white;
  margin-bottom: -1px;
  cursor: pointer;
}
.chat-list img {
  height: 40px;
}
.text-chat {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
}
.admin-chat {
  display: flex;
  align-items: center;
}
.admin-chat img {
  height: 30px;
}
.chat-box-admin {
  background: #f6f6f6;
  border-radius: 10px;
  padding: 15px;
  padding-bottom: 30px;
  margin-top: 10px;
  margin-bottom: 30px;
  width: 90%;
  margin-right: auto;
  position: relative;
}
.chat-box-admin::after {
  content: "7.30 น.";
  font-size: 12px;
  position: absolute;
  bottom: 10px;
  right: 20px;
  color: #b5b5b5;
}
.chat-box-user {
  background: #e6e6e6;
  border-radius: 10px;
  padding: 15px;
  padding-bottom: 30px;
  margin-top: 10px;
  margin-bottom: 30px;
  width: 90%;
  margin-left: auto;
  position: relative;
}
.chat-box-user::after {
  content: "7.30 น.";
  font-size: 12px;
  position: absolute;
  bottom: 10px;
  right: 20px;
  color: #b5b5b5;
}
/* Chat */

@media (min-width: 992px) and (max-width: 1199px) {
}

@media (min-width: 768px) and (max-width: 991px) {
  .center-screen {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 767px) {
  .center-screen {
    display: flex;
  }
}

@media (max-width: 480px) {
}

svg {
  width: 1.2em;
  height: 1.2em;
}
